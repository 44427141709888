.searchBar {
  --action-sheet-content-margin: 0;

  position: relative;
}

.searchBarToggle {
  --button-background-color: rgb(255 255 255 / 45%);
  --button-border-color: currentcolor;
  --button-color: currentcolor;
  --button-padding: var(--spacing-02) var(--spacing-04);

  width: 100%;
}

.searchBarToggle svg {
  stroke-width: 2px;
}

.isScrolled .searchBarToggle {
  --button-background-color: transparent;
}

.searchForm {
  display: flex;
  flex-direction: column;
}

.formField {
  color: var(--theme-accent-color);
  font: var(--typography-paragraph-s);
  padding: var(--spacing-04) 0;
}

.formField + .formField {
  border-top: 1px solid var(--color-gray-200);
}

.searchFormDesktop {
  display: none;
}

.searchFormDesktop .formField:focus-within {
  background: var(--color-gray-050);
}

.formFieldIcon {
  fill: none;
  flex-shrink: 0;
  height: 24px;
  stroke: var(--theme-accent-color);
  stroke-width: 1.5px;
  width: 24px;
}

.formFieldInputWrap {
  display: flex;
  gap: var(--spacing-03);
  margin-top: var(--spacing-01);
}

.formFieldInputWrap > input,
.formFieldInputWrap > select,
.datePickerInput {
  color: var(--color-gray-900);
  font: var(--typography-label-m);
  min-width: 0;
}

.formFieldInputWrap > input,
.formFieldInputWrap > select {
  width: 100%;
}

.formFieldInputWrap > input::placeholder,
.formFieldInputWrap option:disabled,
.formFieldInputWrap select:invalid,
.formFieldInputWrap select[value=""],
.datePickerInputEmpty {
  color: var(--color-gray-900);
  font: var(--typography-paragraph-m);
}

.formFieldInputWrap > input:focus,
.formFieldInputWrap > select:focus {
  outline: 0;
}

.formFieldDropdown {
  margin-top: var(--spacing-04);
}

.datePickerButton {
  align-items: center;
  display: flex;
  gap: var(--spacing-02);
}

.datePickerButton svg {
  fill: none;
  flex-shrink: 0;
  stroke: var(--color-gray-900);
  width: 24px;
}

.datePickerButton:focus {
  outline: 0;
}

.datepicker {
  background: var(--color-gray-000);
  border-top-left-radius: var(--border-radius-s);
  border-top-right-radius: var(--border-radius-s);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-05);
  inset: 0;
  padding: var(--spacing-05);
  position: fixed;
  z-index: 2;
}

.promoCode {
  padding-bottom: 0;
}

.formFieldError {
  color: var(--color-error-500);
}

.formSubmit {
  margin-top: var(--spacing-05);
}

.promotion,
.specialRates {
  background: var(--theme-contrast-background-color);
  border-radius: var(--border-radius-s);
  color: var(--theme-contrast-color);
  font: var(--typography-paragraph-s);
  overflow: hidden;
  padding: var(--spacing-03) var(--spacing-05);
}

.promotionTerms {
  font: var(--typography-paragraph-xs);
}

.specialRates {
  align-items: center;
  display: flex;
  flex-direction: column;
  font: var(--typography-paragraph-xs);
  gap: var(--spacing-01);
  justify-content: space-between;
  padding: var(--spacing-03) var(--spacing-05);
  text-align: center;
}

.specialRatesTitle {
  font: var(--typography-label-s);
}

.specialRatesDiscounts {
  display: grid;
  gap: var(--spacing-02);
  grid-template-columns: 1fr 1fr;
}

.specialRatesDiscountsInline {
  display: flex;
  gap: var(--spacing-05);
}

.specialRatesDisclaimer {
  font-size: 0.85em;
}

@media (--media-l) {
  .searchBar.isOpen::after {
    background-color: var(--color-gray-000);
    box-shadow: 1px 3px 4px 0 rgb(0 0 0 / 5%);
    content: "";
    height: 220px;
    left: 0;
    position: fixed;
    right: 0;
    top: var(--layout-header-height);
    z-index: -1;
  }

  .searchBarToggle {
    visibility: hidden;
  }

  .isScrolled .searchBarToggle {
    visibility: visible;
  }

  .isOpen .searchBarToggle {
    visibility: hidden;
  }

  .searchFormDesktop {
    background: var(--color-gray-000);
    border: 1px solid var(--color-gray-000);
    border-radius: var(--border-radius-s);
    bottom: auto;
    display: block;
    left: 50%;
    padding-left: 0;
    position: absolute;
    transform: translate(-50%, 60px);
    visibility: hidden;
    width: 940px;
  }

  /* stylelint-disable selector-pseudo-class-no-unknown */
  :global(.isKiosk) .searchFormDesktop {
    zoom: 80%;
  }

  .searchForm {
    flex-direction: row;
  }

  .formField {
    flex-basis: 100%;
    padding: var(--spacing-03) var(--spacing-05);
    position: relative;
  }

  .formField + .formField {
    border-left: 1px solid var(--color-gray-200);
    border-top: 0;
  }

  .formFieldLabel {
    white-space: nowrap;
  }

  .datePickerWrap {
    position: relative;
    width: 100%;
  }

  .datepicker {
    border: 1px solid var(--color-gray-000);
    border-radius: 3px;
    inset: auto auto auto 50%;
    position: absolute;
    transform: translate(-50%, var(--spacing-05));
    width: 550px;
  }

  .formFieldDropdown {
    background-color: var(--color-gray-000);
    display: none;
    inset: 100% 0 auto;
    margin-top: 0;
    padding: var(--spacing-04);
    position: absolute;
  }

  .formField:focus-within .formFieldDropdown {
    display: block;
  }

  .formSubmit {
    flex-shrink: 0;
    margin: var(--spacing-03);
  }

  .searchFormDesktop .formSubmit {
    padding: var(--spacing-02) var(--spacing-04);
  }

  .formSubmitText {
    display: none;
  }

  .isOpen .searchFormDesktop,
  .isOpen .datepicker {
    border-color: var(--color-gray-400);
    visibility: visible;
  }

  .searchBar:not(.isScrolled) .searchFormDesktop {
    visibility: visible;
  }

  .specialRatesDiscountsInline {
    display: grid;
    gap: var(--spacing-02);
    grid-template-columns: 1fr 1fr;
  }

  .promotion {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: var(--spacing-01) var(--spacing-05);
  }
}
