/* stylelint-disable selector-class-pattern */
.nav_button {
  position: absolute;
}

.nav_button_previous {
  right: 60px;
}

.nav_button_next {
  right: 30px;
}

.nav_icon {
  height: 12px;
  width: 12px;
}

.months {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-05);
  justify-content: center;
}

.month {
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  flex-grow: 1;
  user-select: none;
}

.table {
  margin-top: var(--spacing-04);
  width: 100%;
}

.tbody {
  display: table-row-group;
}

.caption {
  display: flex;
}

.head_row {
  display: table-row;
}

.head_cell {
  color: var(--color-gray-600);
  display: table-cell;
  font: var(--typography-paragraph-s);
  padding: var(--spacing-01);
  text-align: center;
}

.weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.body {
  display: table-row-group;
}

.week,
.row {
  display: table-row;
}

.cell {
  display: table-cell;
}

.day {
  padding: var(--spacing-02);
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.day_disabled {
  color: var(--color-gray-300);
  cursor: not-allowed;
}

@media (hover: hover) and (pointer: fine) {
  .day:not(.day_disabled):hover {
    background: var(--color-main-500);
    color: var(--color-gray-000);
    cursor: pointer;
  }
}

.day_selected:not(.outside) {
  background: var(--color-main-100);
}

.start {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.end {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}

.start:not(.outside),
.end:not(.outside) {
  background: var(--color-main-700);
  color: var(--color-gray-000);
}

/* Show only for accesibility */
.vhidden {
  appearance: none;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  width: 1px;
}

@media (--media-m) {
  .month:not(:nth-child(1)) {
    display: table;
  }
}
